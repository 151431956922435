import { ApplicationRef, Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { formType, routes } from '@app/app.config';
import * as ls from 'localStorage';
import moment from 'moment';
import { concat, iif, of } from 'rxjs';
import { delay, mergeMap, switchMap } from 'rxjs/operators';
import { IAuthUserResponse, IDataResponse, IUserInfo } from '../app.interface';
import { ApiService } from './api.service';
import { ToolsService } from './tools.service';
import { UserService } from './user.service';

@Injectable()
export class ClientService {
	constructor(
		private api: ApiService,
		private userService: UserService,
		private tools: ToolsService,
		private router: Router,
		private route: ActivatedRoute,
		private _ar: ApplicationRef,
	) {
		// Listen forms state
		tools.formState.subscribe((formsData) => {
			Object.keys(formsData).forEach((_formName) => {
				switch (_formName) {
					case formType.filter:
						{
							this.filterCollectionItemsList(formsData[_formName]);
						}
						break;
					case formType.list:
						{
							this.getCollectionItem(formsData[_formName]);
						}
						break;
					case formType.search:
						{
							this.searchCollectionItem(formsData[_formName]);
						}
						break;
				}
			});
		});
	}

	private _clearUserData = () => {
		this.userService.setUserInfo(null);
		ls.clear();
	};

	login() {
		this._clearUserData();
		if (this.userService.initialized) return;
		this.api.login().subscribe((_res: IDataResponse<IAuthUserResponse>) => {
			if (_res.response) {
				ls.setItem('access-token', _res.response.token);
				this.initUser(_res.response.user);
			}
		});
	}

	logout() {
		this.api.logout().subscribe((_res: IDataResponse<boolean>) => {
			if (_res.response) {
				this._clearUserData();
				if (this.router.routerState.snapshot.url.indexOf('login') === -1) {
					// Works ONLY when user manually logout
					this.router.navigate(['login']);
				}
			}
		});
	}

	serverLost() {
		this._clearUserData();
		this.router.navigate(['502']);
	}

	initUser(_user: IUserInfo) {
		this.userService.setUserInfo(_user);
		this.router.navigate([this.route.snapshot.queryParams.returnUrl || 'editor']);
	}

	loadCollectionItemsList() {
		try {
			const _dbColl = this.tools.routerConfig.dbColl;
			this.api.collectionItems(_dbColl).subscribe();
		} catch (e) {
			console.error(e);
		}
	}

	filterCollectionItemsList(data) {
		try {
			const _dbColl = this.tools.routerConfig.dbColl;
			this.api.filterCollectionItems(_dbColl, data).subscribe();
		} catch (e) {
			console.error(e);
		}
	}

	getCollectionItem(data) {
		if (Object.keys(data).length > 0 && data.selectedItem && !Array.isArray(data.selectedItem)) {
			try {
				this.tools.setEditorState('load');
				const _dbColl = this.tools.routerConfig.dbColl;
				this.api.getCollectionItem(_dbColl, data.selectedItem._id).subscribe();
			} catch (e) {
				console.error(e);
			}
		}
	}

	updateCollectionItem(data, syncAll = false) {
		try {
			const _dbColl = this.tools.routerConfig.dbColl;
			const _filters = this.tools.routerConfig.filters;
			const _route = this.tools.routerConfig.route;
			const _lokaliseData = this.tools.routerConfig.lokaliseRouteData;

			if (this.tools.routerConfig.resetRouteDataOnUpdate) {
				this.tools.clearRouteContextState(_route);
				this.tools.clearLokaliseContextState(_route);
			}

			const _filterData = this.tools.formsValue('filter', true);

			// Remove key from form values if user has no 'Approve' permission
			if (!this.userService.toApprove() && this.tools.routerState === routes.fields) {
				delete data.key;
			}
			this.api
				.updateCollectionItem(_dbColl, data._id, { ...data, syncAll })
				.pipe(
					delay(150),
					switchMap((_) =>
						iif(
							() => !!_filters && _filters.length > 0,
							this.api.filterCollectionItems(_dbColl, _filterData || {}),
							this.api.collectionItems(_dbColl),
						),
					),
					mergeMap((_) =>
						iif(() => !!_lokaliseData || _lokaliseData.length >= 1, this.api.getLokaliseData(_lokaliseData)),
					),
				)
				.subscribe((_) => {
					this.tools.setEditorState('edit');
					this._ar.tick();
				});
		} catch (e) {
			console.error(e);
			this.tools.setEditorState('wait');
		}
	}

	exportCollectionItems(data) {
		try {
			const _dbColl = this.tools.routerConfig.dbColl;

			data = data.map((el) => el._id);
			this.api.exportCollectionItems(_dbColl, data).subscribe((res: any) => {
				this.tools.setEditorState('wait');
				this._ar.tick();

				var newBlob = new Blob([JSON.stringify(res.data)], { type: 'application/json' });
				if (window.navigator && window.navigator.msSaveOrOpenBlob) {
					window.navigator.msSaveOrOpenBlob(newBlob);
					return;
				}

				const data = window.URL.createObjectURL(newBlob);

				var fileName = 'backup_' + moment().format('YYYY-MM-DD HH:MM') + '.json';
				var link = document.createElement('a');
				link.href = data;
				link.download = fileName;
				link.textContent = 'Download ' + fileName;
				link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

				setTimeout(function () {
					window.URL.revokeObjectURL(data);
					link.remove();
				}, 100);
			});
		} catch (e) {
			console.error(e);
			this.tools.setEditorState('wait');
		}
	}

	addNewCollectionItem(data) {
		try {
			const _dbColl = this.tools.routerConfig.dbColl;
			const _filters = this.tools.routerConfig.filters;
			const _lokaliseData = this.tools.routerConfig.lokaliseRouteData;

			const _filterData = this.tools.formsValue('filter', true);

			this.api
				.addNewCollectionItem(_dbColl, data)
				.pipe(
					delay(150),
					switchMap((_) =>
						iif(
							() => !!_filters && _filters.length > 0,
							this.api.filterCollectionItems(_dbColl, _filterData || {}),
							this.api.collectionItems(_dbColl),
						),
					),
					mergeMap((_) =>
						iif(() => !!_lokaliseData || _lokaliseData.length >= 1, this.api.getLokaliseData(_lokaliseData)),
					),
				)
				.subscribe((_) => {
					this.tools.setEditorState('edit');
					this._ar.tick();
				});
		} catch (e) {
			console.error(e);
			this.tools.setEditorState('wait');
		}
	}

	deleteCollectionItem(data: any[]) {
		try {
			const _dbColl = this.tools.routerConfig.dbColl;
			const _filters = this.tools.routerConfig.filters;
			const _lokaliseData = this.tools.routerConfig.lokaliseRouteData;

			const _filterData = this.tools.formsValue('filter', true);

			data = data.map((el) => el._id);
			this.api
				.deleteCollectionItem(_dbColl, data)
				.pipe(
					delay(150),
					switchMap((_) =>
						iif(
							() => !!_filters && _filters.length > 0,
							this.api.filterCollectionItems(_dbColl, _filterData || {}),
							this.api.collectionItems(_dbColl),
						),
					),
					mergeMap((_) =>
						iif(() => !!_lokaliseData || _lokaliseData.length >= 1, this.api.getLokaliseData(_lokaliseData)),
					),
				)
				.subscribe((_) => {
					this.tools.setEditorState('wait');
					this._ar.tick();
				});
		} catch (e) {
			console.error(e);
			this.tools.contentLocked.next(true);
			this.tools.setEditorState('wait');
		}
	}

	searchCollectionItem(data) {
		if (data.query && data.query.length > 0) {
			data.language = data.language._id;
			data.collection = this.tools.routerState;
			this.tools.contextLoading.next(true);
			this.api.searchCollectionItem(data).subscribe();
		}
	}

	getFullCollection() {
		try {
			const _config = this.tools.routerConfig;
			this.api.getFullCollection(_config.dbColl);
		} catch (e) {
			console.error(e);
		}
	}

	addNewMisc(_item) {
		const _config = this.tools.routerConfig;
		this.api
			.addNewMisc(_config.dbColl, _item)
			.pipe(
				delay(250),
				switchMap((_: any) => iif(() => _ && _._id, this.api.getFullCollection(_config.dbColl), of(_))),
			)
			.subscribe();
	}

	updateMiscFile(_item) {
		const _config = this.tools.routerConfig;
		this.api
			.updateMiscFile(_config.dbColl, _item)
			.pipe(
				delay(250),
				switchMap((_) => this.api.getFullCollection(_config.dbColl)),
			)
			.subscribe();
	}

	updateArticleMisc(data) {
		const formData = new FormData();
		formData.append('title', data.title);
		formData.append('description', data.description);
		formData.append('order', data.order);
		if (typeof data.file !== 'string') {
			formData.append('file', data.file, data.file.name);
		}
		if (typeof data.image !== 'string') {
			formData.append('image', data.image, data.image.name);
		}
		formData.append('language', data.language);
		formData.append('categories', data.categories);
		formData.append('readingTime', data.readingTime.toString());
		formData.append('_id', data._id);
		formData.append('_author', data._author);
		formData.append('author', JSON.stringify(data.author));

		const _config = this.tools.routerConfig;
		this.api
			.updateMisc(_config.dbColl, data._id, formData)
			.pipe(
				delay(250),
				switchMap((_) => this.api.getFullCollection(_config.dbColl)),
			)
			.subscribe();
	}

	updateMisc(_item) {
		const _config = this.tools.routerConfig;
		this.api
			.updateMisc(_config.dbColl, _item._id, _item)
			.pipe(
				delay(250),
				switchMap((_) => this.api.getFullCollection(_config.dbColl)),
			)
			.subscribe();
	}

	deleteMisc(_item) {
		const _config = this.tools.routerConfig;
		this.api
			.deleteMisc(_config.dbColl, _item._id)
			.pipe(
				delay(250),
				switchMap((_) => this.api.getFullCollection(_config.dbColl)),
			)
			.subscribe();
	}

	reorderMisc(collection_items) {
		const _config = this.tools.routerConfig;
		this.api
			.reorderMisc(_config.dbColl, collection_items)
			.pipe(
				delay(250),
				switchMap((_) => this.api.getFullCollection(_config.dbColl)),
			)
			.subscribe();
	}

	approveLog(item) {
		try {
			this.api.approveLog(item._id).subscribe();
		} catch (e) {
			console.error(e);
		}
	}

	checkLokaliseToken(token: string) {
		if (!token || token.length < 38) return;
		const _route = this.tools.routerConfig.route;

		this.api.checkLokaliseToken(token).subscribe(() => {
			this.tools.setEditorState('edit');
			this._ar.tick();
		});
	}

	updateLokaliseData() {
		const _route = this.tools.routerConfig.route;
		const _lokaliseData = this.tools.routerConfig.lokaliseRouteData;

		if (this.tools.routerConfig.resetRouteDataOnUpdate) {
			this.tools.clearLokaliseContextState(_route);
		}

		if (_lokaliseData || _lokaliseData.length >= 1) {
			this.api.getLokaliseData(_lokaliseData).subscribe();
		}
	}

	// force field(s) value to lokalise, the lokalise data will be overloaded !!!
	syncFieldsToLokalise(fieldsId: any[], syncAllLanguages = null) {
		fieldsId = fieldsId.map((el) => el._id);

		this.api.syncFieldsToLokalise(fieldsId, syncAllLanguages).subscribe(() => {
			if (fieldsId.length == 1) {
				if (!this.tools.editorStateValue.edit) this.tools.setEditorState('edit');
			} else {
				this.tools.setEditorState('wait');
			}
			this._ar.tick();
		});
	}

	// force lokalise value to field(s), the filed(s) value will be overloaded !!!
	syncWithLokalise(fieldsId: any[], syncAllLanguages = null) {
		const _lokaliseData = this.tools.routerConfig.lokaliseRouteData;

		if (_lokaliseData || _lokaliseData.length >= 1) {
			fieldsId = fieldsId.map((el) => el._id);

			this.api.syncWithLokalise(fieldsId, syncAllLanguages).subscribe(() => {
				if (fieldsId.length == 1) {
					this.getCollectionItem({ selectedItem: { _id: fieldsId[0] } });
					this.tools.setEditorState('edit');
				}

				this.api.getLokaliseData(_lokaliseData).subscribe();
				this._ar.tick();
			});
		}
	}

	reloadLokaliseData() {
		const _lokaliseData = this.tools.routerConfig.lokaliseRouteData;

		if (_lokaliseData || _lokaliseData.length >= 1) {
			this.api.getLokaliseData(_lokaliseData).subscribe();
		}
	}

	resetLokalise() {
		const _route = this.tools.routerConfig.route;
		const _lokaliseData = this.tools.routerConfig.lokaliseRouteData;

		this.tools.clearLokaliseContextState(_route);

		concat(this.api.resetLokalise(), this.api.getLokaliseData(_lokaliseData)).subscribe();
	}

	updateLokaliseTags() {
		this.tools.setEditorState('load');
		this.api.updateLokaliseTags().subscribe(() => {
			this.tools.setEditorState('edit');
			this._ar.tick();
		});
	}
}
