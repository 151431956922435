import { InjectionToken } from '@angular/core';
import { languagesAll } from 'countries-list';
import { ToastService } from './shared/services/toast.service';

export const HTTP_TIMEOUT = new InjectionToken<number>('defaultTimeout');
export const HTTP_LOKALISE_TIMEOUT = new InjectionToken<number>('lokaliseTimeout');
export const TOAST = new InjectionToken<ToastService>('toast');
export const ROUTE_EVENT = new InjectionToken<ToastService>('route_event');

export const LANG_DETECTION_API_TOKEN = 'c93e765552aa3443c3db5d502940bcd3';
export const LOKALISE_API_TOKEN = 'd3aa6f8bf380a1ac2e54689f56c973269ff3d56f';

export type Nullable<T> = { [P in keyof T]: T[P] | null };
export const _typify = <T>(_) => _ as T;

// different form _routerOptions, don't require to load a dbColl
export const projectSecuredRoutes = ['editor'];

export const allLanguages = languagesAll;

export enum permission {
	all = '**',
	create = 'create',
	read = 'read',
	update = 'update',
	delete = 'delete',
	approve = 'approve',
}

export enum pagesToProjectSelect {
	all = '**',
	portalAll = '*portal*',
	mainAll = '*main*',
	lpAll = '*lp*',
	custom = '',
}

export enum optionTypes {
	text = 'text',
	number = 'number',
	checkbox = 'checkbox',
	date = 'date',
	time = 'time',
	array = 'array',
}

// '_id' is an input type !
export const optionTypesList = [
	{ _id: optionTypes.text, name: 'String' },
	{ _id: optionTypes.number, name: 'Number' },
	{ _id: optionTypes.checkbox, name: 'Boolean' },
	{ _id: optionTypes.date, name: 'Date' },
	{ _id: optionTypes.time, name: 'Time' },
	{ _id: optionTypes.array, name: 'Array' },
];

export const builderKinds = [
	{ label: 'String', value: 'String' },
	{ label: 'Array', value: 'Array' },
	{ label: 'Object', value: 'Object' },
];

export enum projects {
	portal = 'Portal',
	main = 'Main',
	lp = 'Lp',
}

export enum jurisdictions {
	CIMA = 'CIMA',
	FCA = 'FCA',
	CySEC = 'CySEC',
}

export enum contentBuilderType {
	table = 'Table',
	string = 'String',
	html = 'Html',
}

export enum userRoles {
	superadmin = 'superadmin',
	admin = 'admin',
	manager = 'manager',
	developer = 'developer',
}

export enum fieldKeyPrefix {
	label = 'label',
	text = 'text',
	button = 'button',
	link = 'link',
	title = 'title',
	tab = 'tab',
	validation = 'validation',
	status = 'status',
	notification = 'notification',
}

// list of all collections supported by the app
export enum _db {
	buildertypes = 'buildertypes',
	fields = 'fields',
	jurisdictions = 'jurisdictions',
	languages = 'languages',
	logs = 'logs',
	pages = 'pages',
	projects = 'projects',
	roles = 'roles',
	users = 'users',
	prefixes = 'prefixes',
	files = 'files',
	videos = 'videos',
	news = 'news',
	categories = 'categories',
	articles = 'articles',
}

export enum _localise_db {
	token = 'token',
	projects = 'projects',
	webhooks = 'webhooks',
	languages = 'languages',
	reports = 'reports',
	keys = 'keys',
	onlyVerified = 'onlyVerified',
	__projectsList = '__projectsList',
}

export enum formType {
	auth = 'auth',
	filter = 'filter',
	list = 'list',
	editor = 'editor',
	search = 'search',
	video = 'video',
	article = 'article',
	blogArticle = 'blogArticle',
}

export enum formDataType {
	data = 'data',
	lokalise = 'lokalise',
}

export enum routes {
	infoUrl = '/editor',
	fields = 'fields',
	files = 'files',
	pages = 'pages',
	users = 'users',
	logs = 'logs',
	videos = 'videos',
	news = 'news',
	languages = 'languages',
	jurisdictions = 'jurisdictions',
	projects = 'projects',
	roles = 'roles',
	builders = 'builders',
	categories = 'categories',
	articles = 'articles',
}

export enum editorState {
	wait = 'wait',
	load = 'load',
	edit = 'edit',
	save = 'save',
	add = 'add',
	delete = 'delete',
}

export enum routeState {
	ActivationStart = 'ActivationStart',
	NavigationStart = 'NavigationStart',
	NavigationEnd = 'NavigationEnd',
	ActivationEnd = 'ActivationEnd',
}

export enum apiAction {
	user = 'user',
	logout = 'logout',
	_data = '_data',
	list = 'list',
	editorItem = 'editorItem',
	search = 'search',
	fileAction = 'fileAction',
	misc = 'misc',
	approve = 'approve',
	logs = 'logs',
	lokalise = 'lokalise',
}

export enum toastSeverity {
	success = 'success',
	info = 'info',
	warn = 'warn',
	error = 'error',
}

// Show search input for some of the filters
export const _searchableFilters = ['page', 'author'];
export const _formsFieldToDBMap = {
	parent: _db.projects,
	project: _db.projects,
	builder: _db.buildertypes,
	jurisdiction: _db.jurisdictions,
	page: _db.pages,
	author: _db.users,
	role: _db.roles,
	// prefix: _db.prefixes,
	builderType: _db.buildertypes,
	pages: _db.pages,
	language: _db.languages,
};
export const _formsFieldToDbFieldMap = {
	id: '_id',
	pages: '_pages',
	builderType: '_builderType',
	role: '_role',
	author: '_author',
	project: '_project',
	parent: '_parent',
	language: '_language',
	jurisdiction: '_jurisdiction',
	prefix: '_prefix',
	password: 'hash',
};

// dbColl -> collection name in DB
export const _routerOptions = [
	{
		label: 'Editor',
		routeUrl: routes.infoUrl,
		dbColl: _db.logs,
		routeData: [
			_db.buildertypes,
			_db.fields,
			_db.jurisdictions,
			_db.languages,
			_db.logs,
			_db.pages,
			_db.projects,
			_db.roles,
			_db.users,
			// _db.prefixes,
			_db.files,
			_db.videos,
			_db.news,
			_db.categories,
		],
		resetRouteDataOnUpdate: false,
		lokaliseRouteData: [],
		menuItem: false,
	},
	{
		label: 'Fields',
		route: routes.fields,
		dbColl: _db.fields,
		filters: ['project', 'page', 'prefix', 'author', 'builder', 'jurisdiction', 'onReview'],
		formFields: [
			'id',
			'key',
			'name',
			'pages',
			'useContentFrom',
			'content',
			'builderType',
			'author',
			'options',
			'createdAt',
			'updatedAt',
		],
		routeData: [_db.projects, _db.jurisdictions, _db.languages, _db.users, _db.pages, _db.buildertypes],
		resetRouteDataOnUpdate: false,
		lokaliseRouteData: [_localise_db.token, _localise_db.projects, _localise_db.languages],
		menuItem: true,
	},
	{
		label: 'Categories',
		route: routes.categories,
		dbColl: _db.categories,
		formFields: ['id', 'key', 'name', 'content', 'builderType', 'author', 'createdAt', 'updatedAt'],
		routeData: [_db.projects, _db.jurisdictions, _db.languages, _db.users, _db.pages, _db.buildertypes],
		localiseRouteData: [],
		resetRouteDataOnUpdate: true,
		menuItem: true,
	},
	{
		label: 'Pages',
		route: routes.pages,
		dbColl: _db.pages,
		filters: ['project', 'author', 'jurisdiction', 'onReview'],
		formFields: ['id', 'uri', 'name', 'meta', 'author', 'project', 'createdAt', 'updatedAt'],
		routeData: [_db.jurisdictions, _db.languages, _db.users, _db.projects],
		resetRouteDataOnUpdate: true,
		lokaliseRouteData: [_localise_db.token, _localise_db.projects, _localise_db.languages],
		menuItem: true,
	},
	{
		label: 'Documents',
		parentLabel: 'Misc',
		route: routes.files,
		dbColl: _db.files,
		routeData: [_db.users],
		resetRouteDataOnUpdate: false,
		lokaliseRouteData: [],
		menuItem: true,
	},
	{
		label: 'Videos',
		parentLabel: 'Misc',
		route: routes.videos,
		dbColl: _db.videos,
		formFields: ['name', 'order', 'videoAuthor', 'videoID'],
		routeData: [_db.users],
		resetRouteDataOnUpdate: false,
		lokaliseRouteData: [],
		menuItem: true,
	},
	{
		label: 'News',
		parentLabel: 'Misc',
		route: routes.news,
		dbColl: _db.news,
		formFields: ['title', 'order', 'file', 'date'],
		routeData: [_db.users],
		resetRouteDataOnUpdate: false,
		lokaliseRouteData: [],
		menuItem: true,
	},
	{
		label: 'Articles',
		parentLabel: 'Misc',
		route: routes.articles,
		dbColl: _db.articles,
		formFields: ['title', 'order', 'file'],
		routeData: [_db.users, _db.categories, _db.languages],
		resetRouteDataOnUpdate: false,
		lokaliseRouteData: [],
		menuItem: true,
	},
	{
		label: 'Users',
		route: routes.users,
		dbColl: _db.users,
		filters: ['role'],
		formFields: [
			'id',
			'name',
			'hash',
			'email',
			'role',
			'lokaliseToken',
			'lokaliseProjects',
			'lokaliseLanguages',
			'lokaliseWebhooks',
			'lokaliseOnlyVerified',
		],
		routeData: [_db.roles, _db.languages, _db.jurisdictions],
		resetRouteDataOnUpdate: true,
		lokaliseRouteData: [
			_localise_db.token,
			_localise_db.projects,
			_localise_db.webhooks,
			_localise_db.languages,
			_localise_db.onlyVerified,
			_localise_db.__projectsList,
		],
		menuItem: true,
	},
	{
		label: 'Languages',
		parentLabel: 'Other',
		route: routes.languages,
		dbColl: _db.languages,
		formFields: ['id', 'name', 'value', 'native', 'rtl', 'deprecated'],
		routeData: [_db.languages],
		resetRouteDataOnUpdate: true,
		lokaliseRouteData: [],
		menuItem: true,
	},
	{
		label: 'Jurisdictions',
		parentLabel: 'Other',
		route: routes.jurisdictions,
		dbColl: _db.jurisdictions,
		filters: ['project'],
		formFields: ['id', 'name', 'domain', 'project'],
		routeData: [_db.projects, _db.languages],
		resetRouteDataOnUpdate: true,
		lokaliseRouteData: [],
		menuItem: true,
	},
	{
		label: 'Projects',
		parentLabel: 'Other',
		route: routes.projects,
		dbColl: _db.projects,
		formFields: ['id', 'name', 'parent'],
		routeData: [_db.projects, _db.languages],
		resetRouteDataOnUpdate: true,
		lokaliseRouteData: [],
		menuItem: true,
	},
	{
		label: 'Roles',
		parentLabel: 'Other',
		route: routes.roles,
		dbColl: _db.roles,
		formFields: ['id', 'name', 'permissions'],
		routeData: [_db.languages],
		resetRouteDataOnUpdate: true,
		lokaliseRouteData: [],
		menuItem: true,
	},
	{
		label: 'Builders',
		parentLabel: 'Other',
		route: routes.builders,
		dbColl: _db.buildertypes,
		formFields: ['id', 'name', 'description'],
		routeData: [_db.languages],
		resetRouteDataOnUpdate: true,
		lokaliseRouteData: [],
		menuItem: true,
	},
	// {
	// 	label: 'Prefix',
	// 	parentLabel: 'Other',
	// 	route: routes.prefixes,
	// 	dbColl: _db.prefixes,
	// 	formFields: ['id', 'name', 'key', 'description'],
	// 	routeData: [_db.languages],
	// 	resetRouteDataOnUpdate: true,
	// 	lokaliseRouteData: [],
	// },
];

export const _userOptions = {
	[userRoles.superadmin]: {
		[formType.editor]: {
			[editorState.edit]: {
				disabledFields: ['name', 'key', 'prefix', 'builderType'],
			},
		},
	},
};

export const messageCode = [
	{
		_id: 201,
		kind: 'objNotExist',
		summaryMsg: 'Object Not Exist',
		detailMsg: ' ',
		severity: toastSeverity.warn,
	},
	{
		_id: 102,
		kind: 'dataFetched',
		summaryMsg: 'Data Fetched',
		detailMsg: ' ',
		severity: toastSeverity.info,
	},
	{
		_id: 203,
		kind: 'badRequest',
		summaryMsg: 'Bad Request',
		detailMsg: ' ',
		severity: toastSeverity.error,
	},
	{
		_id: 204,
		kind: 'mongooseIssue',
		summaryMsg: 'Mongoose Issue',
		detailMsg: ' ',
		severity: toastSeverity.error,
	},
	{
		_id: 205,
		kind: 'objDeleteFailure',
		summaryMsg: 'Object Delete Failure',
		detailMsg: ' ',
		severity: toastSeverity.error,
	},
	{
		_id: 106,
		kind: 'objDeleteSuccess',
		summaryMsg: 'Object Delete Success',
		detailMsg: ' ',
		severity: toastSeverity.success,
	},
	{
		_id: 207,
		kind: 'objCreateFailure',
		summaryMsg: 'Object Create Failure',
		detailMsg: ' ',
		severity: toastSeverity.error,
	},
	{
		_id: 108,
		kind: 'objCreateSuccess',
		summaryMsg: 'Object Create Success',
		detailMsg: ' ',
		severity: toastSeverity.success,
	},
	{
		_id: 109,
		kind: 'objUpdateSuccess',
		summaryMsg: 'Object Update Success',
		detailMsg: ' ',
		severity: toastSeverity.success,
	},
	{
		_id: 210,
		kind: 'objUpdateFailure',
		summaryMsg: 'Object Update Failure',
		detailMsg: ' ',
		severity: toastSeverity.error,
	},
	{
		_id: 211,
		kind: 'unauthorized',
		summaryMsg: 'Unauthorized',
		detailMsg: ' ',
		severity: toastSeverity.warn,
	},
	{
		_id: 212,
		kind: 'emptyResponse',
		summaryMsg: 'Empty Response',
		detailMsg: ' ',
		severity: toastSeverity.warn,
	},
	{
		_id: 113,
		kind: 'importSuccess',
		summaryMsg: 'Import Success',
		detailMsg: ' ',
		severity: toastSeverity.success,
	},
	{
		_id: 214,
		kind: 'importFailure',
		summaryMsg: 'Import Failure',
		detailMsg: ' ',
		severity: toastSeverity.error,
	},
	{
		_id: 215,
		kind: 'pageNotExists',
		summaryMsg: 'Page Not Exists',
		detailMsg: ' ',
		severity: toastSeverity.warn,
	},
	{
		_id: 116,
		kind: 'loginSuccess',
		summaryMsg: 'Login Success',
		detailMsg: ' ',
		severity: toastSeverity.success,
	},
	{
		_id: 217,
		kind: 'loginFailure',
		summaryMsg: 'Login Failure',
		detailMsg: ' ',
		severity: toastSeverity.error,
	},
	{
		_id: 118,
		kind: 'logoutSuccess',
		summaryMsg: 'Logout Success',
		detailMsg: ' ',
		severity: toastSeverity.success,
	},
	{
		_id: 219,
		kind: 'logoutFailure',
		summaryMsg: 'Logout Failure',
		detailMsg: ' ',
		severity: toastSeverity.error,
	},
	{
		_id: 120,
		kind: 'correctToken',
		summaryMsg: 'Correct Token',
		detailMsg: ' ',
		severity: toastSeverity.warn,
	},
	{
		_id: 221,
		kind: 'incorrectToken',
		summaryMsg: 'Incorrect Token',
		detailMsg: ' ',
		severity: toastSeverity.error,
	},
	{
		_id: 222,
		kind: 'tokenRevoked',
		summaryMsg: 'Token Revoked',
		detailMsg: ' ',
		severity: toastSeverity.error,
	},
	{
		_id: 223,
		kind: 'nothingToUpdate',
		summaryMsg: 'Nothing To Update',
		detailMsg: ' ',
		severity: toastSeverity.warn,
	},
	{
		_id: 110,
		kind: 'fileUploadSuccess',
		summaryMsg: 'File Upload',
		detailMsg: 'Success',
		severity: toastSeverity.success,
	},
	{
		_id: 224,
		kind: 'fileUploadedButNotAddedToDB',
		summaryMsg: 'File Upload',
		detailMsg: 'Uploaded but not added to DB',
		severity: toastSeverity.warn,
	},
	{
		_id: 225,
		kind: 'fileAlreadyExists',
		summaryMsg: 'File Upload',
		detailMsg: '',
		severity: toastSeverity.warn,
	},
	{
		_id: 226,
		kind: 'fileUploadFailure',
		summaryMsg: 'File Upload',
		detailMsg: 'Failure',
		severity: toastSeverity.error,
	},
	{
		_id: 227,
		kind: 'fileUploadedButNotAddedToLOGS',
		summaryMsg: 'File Upload',
		detailMsg: 'Uploaded but not added to LOGS',
		severity: toastSeverity.warn,
	},
	{
		_id: 111,
		kind: 'fileDeletedSuccess',
		summaryMsg: 'File Delete',
		detailMsg: 'Success',
		severity: toastSeverity.success,
	},
	{
		_id: 228,
		kind: 'fileDeletedFailure',
		summaryMsg: 'File Delete',
		detailMsg: 'Failure',
		severity: toastSeverity.error,
	},
];
