import { Component } from '@angular/core';
import { ClientService } from '@app/shared/services/client.service';
import { ToolsService } from '@app/shared/services/tools.service';

@Component({
	selector: 'file-editor',
	templateUrl: './files.component.html',
})
export class FilesFormComponent {
	files = [];
	origin = window.location.origin;
	uploadError = '';

	constructor(public tools: ToolsService, private client: ClientService) {
		tools.apiStates(['misc', '_data']).subscribe((_) => {
			this.files =
				_.misc.map((item) =>
					Object.assign({}, item, { author: _._data.users.find((_user) => _user._id == item.author) }),
				) || [];
		});
	}

	handleSelect($event: any, maxFileSize: number): void {
		const fileSize = $event.files[0].size;

		if (fileSize > maxFileSize) {
			this.uploadError = `${byteToMb(fileSize)}MB: Invalid file size. Maximum upload size is ${byteToMb(
				maxFileSize,
			)}MB.`;
			setTimeout(() => (this.uploadError = ''), 5000);
		}

		function byteToMb(bytes: number) {
			return (bytes / 1000 / 1000).toFixed(2);
		}
	}

	uploadHandler(e) {
		this.tools.contextLoading.next(true);
		this.client.addNewMisc(e.files[0]);
	}

	onRowDeleteInit(e) {
		this.client.deleteMisc(e);
	}

	onRowUpdateInit(e, fileName) {
		this.client.updateMiscFile(Object.assign({}, e, { name: fileName }));
	}
}
