import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { ContenteditableModule } from 'ng-contenteditable';
import { MessageService } from 'primeng/api';
import { TooltipModule } from 'primeng/tooltip';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_LOKALISE_TIMEOUT, HTTP_TIMEOUT, ROUTE_EVENT, routeState } from './app.config';
import { LoginComponent } from './components/auth/login.component';
import { ArrayBuilderComponent } from './components/editor/edit-form/content-builder/builders/array-builder.component';
import { HtmlBuilderComponent } from './components/editor/edit-form/content-builder/builders/html-builder.component';
import { StringBuilderComponent } from './components/editor/edit-form/content-builder/builders/string-builder.component';
import { EditFormContentBuilderComponent } from './components/editor/edit-form/content-builder/content-builder.component';
import { ContentFinderComponent } from './components/editor/edit-form/content-finder/content-finder.component';
import { ContentOptionsComponent } from './components/editor/edit-form/content-options/content-options.component';
import { LocalOptionsComponent } from './components/editor/edit-form/content-options/local-options/local-options.component';
import { EditFormComponent } from './components/editor/edit-form/edit-form.component';
import { EditorComponent } from './components/editor/editor.component';
import { FilterComponent } from './components/editor/filter/filter.component';
import { LokaliseInfoComponent } from './components/editor/lokalise-info/lokalise-info.component';
import { AddArticleComponent } from './components/editor/misc-form/articles/add-article/add-article.component';
import { ArticlesFormComponent } from './components/editor/misc-form/articles/articles.component';
import { FilesFormComponent } from './components/editor/misc-form/files/files.component';
import { AddNewsComponent } from './components/editor/misc-form/news/add-news/add-news.component';
import { NewsFormComponent } from './components/editor/misc-form/news/news.component';
import { AddVideoComponent } from './components/editor/misc-form/videos/add-video/add-video.component';
import { VideosFormComponent } from './components/editor/misc-form/videos/videos.component';
import { OverallInfoComponent } from './components/editor/overall-info/overall-info.component';
import { SideMenuComponent } from './components/editor/side-menu/side-menu.component';
import { HeaderComponent } from './components/header/header.component';
import { MainComponent } from './components/main.component';
import { ApiInterceptor } from './helpers/api.interceptor';
import { JwtInterceptor } from './helpers/jwt.interceptor';
import { RestErrorInterceptor } from './helpers/restError.interceptor';
import { TimeoutInterceptor } from './helpers/timeout.interceptor';
import { PrimengModule } from './shared/primeng.module';
import { ApiService } from './shared/services/api.service';
import { ClientService } from './shared/services/client.service';
import { ToastService } from './shared/services/toast.service';
import { UserService } from './shared/services/user.service';
import { SharedModule } from './shared/shared.module';
import { AngularEditorModule } from '@kolkov/angular-editor';

@NgModule({
	imports: [
		AppRoutingModule,
		BrowserAnimationsModule,
		BrowserModule,
		CommonModule,
		FormsModule,
		HttpClientModule,
		PrimengModule,
		ReactiveFormsModule,
		RouterModule,
		ContenteditableModule,
		TooltipModule,
		SharedModule,
		AngularEditorModule
	],
	declarations: [
		AppComponent,
		EditFormComponent,
		EditFormContentBuilderComponent,
		EditorComponent,
		HeaderComponent,
		LoginComponent,
		MainComponent,
		SideMenuComponent,
		ContentFinderComponent,
		FilesFormComponent,
		VideosFormComponent,
		NewsFormComponent,
		AddVideoComponent,
		AddArticleComponent,
		AddNewsComponent,
		ArticlesFormComponent,
		ContentOptionsComponent,
		LocalOptionsComponent,
		OverallInfoComponent,
		LokaliseInfoComponent,
		StringBuilderComponent,
		FilterComponent,
		ArrayBuilderComponent,
		HtmlBuilderComponent,
	],
	entryComponents: [ContentFinderComponent, AddVideoComponent, LocalOptionsComponent],
	bootstrap: [AppComponent],
	providers: [
		ToastService,
		ApiService,
		UserService,
		ClientService,
		MessageService,
		{ provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
		// { provide: HTTP_INTERCEPTORS, useClass: HttpsInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: TimeoutInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: RestErrorInterceptor, multi: true },
		{ provide: HTTP_TIMEOUT, useValue: 60000 },
		{ provide: HTTP_LOKALISE_TIMEOUT, useValue: 300000 },
		{
			provide: ROUTE_EVENT,
			useValue: {
				[routeState.ActivationStart]: [],
				[routeState.NavigationStart]: [],
				[routeState.ActivationEnd]: [],
				[routeState.NavigationEnd]: [],
			},
		},
	],
})
export class AppModule {}
