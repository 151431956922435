export const calculateReadingTime = (htmlContent) => {
	const plainText = htmlContent.replace(/<[^>]+>/g, '');
	const wordsPerMinute = 200;
	const wordCount = plainText.split(/\s+/).length;

	return Math.ceil(wordCount / wordsPerMinute);
};

export const getFileReadingTime = (file) => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();

		reader.onload = (e) => {
			const htmlContent = e.target.result;
			const readingTime = calculateReadingTime(htmlContent);
			resolve({ readingTime: readingTime, htmlContent: htmlContent });
		};

		reader.onerror = (error) => {
			reject(error);
		};

		reader.readAsText(file);
	});
};

export const getFileAsText = (file) => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();

		reader.onload = (e) => {
			resolve(e.target.result);
		};

		reader.onerror = (error) => {
			reject(error);
		};

		reader.readAsText(file);
	});
};
