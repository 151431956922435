import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { HTTP_TIMEOUT, _db, _localise_db, apiAction } from '@app/app.config';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { EMPTY, Notification, of, throwError } from 'rxjs';
import { catchError, concatMap, map, materialize, mergeMap, tap } from 'rxjs/operators';
import { BaseResponse, IDataResponse } from '../app.interface';
import { FormsProviderService } from './forms.service';
import { ToastService } from './toast.service';
import { ToolsService } from './tools.service';
import { UserService } from './user.service';

@Injectable()
export class ApiService implements OnDestroy {
	constructor(
		private http: HttpClient,
		private userService: UserService,
		private formsService: FormsProviderService,
		private tools: ToolsService,
		private toast: ToastService,
		@Inject(HTTP_TIMEOUT) private requestTimeout: number,
	) {}

	login() {
		const userAuthorized = this.userService.authorized;
		if (!userAuthorized) {
			return this._actionResponse(
				apiAction.user,
				this.http.post('/login', this.formsService.authForm.value).pipe(
					untilDestroyed(this),
					concatMap((response: BaseResponse) => {
						if (response.data.token) {
							this.formsService.authForm.reset();
							return of(response.data);
						}
						this._message(response.data);
					}),
				),
			);
		}
	}

	logout() {
		return this._actionResponse(
			apiAction.logout,
			this.http.post('/logout', { token: this.userService.token }).pipe(
				untilDestroyed(this),
				concatMap((response: BaseResponse) => {
					return of(response.data);
				}),
			),
		);
	}

	user() {
		const token = this.userService.token;
		if (token) {
			return this._actionResponse(
				apiAction.user,
				this.http.post('/user', { token }).pipe(
					untilDestroyed(this),
					concatMap((response: BaseResponse) => {
						if (response.data.token) {
							return of(response.data);
						}
						this._message(response.data);
					}),
				),
			);
		}
	}

	// Collect all necessary information to route
	collectionsListItems(collections: _db[]) {
		return this._actionResponse(apiAction._data, this.http.post('/allItems', { collections }));
	}

	// Returns all item with all params within it
	getFullCollection(collectionName) {
		return this._actionResponse(apiAction.misc, this.http.get(`/collections/${collectionName}/full`));
	}

	// Get all items of a collection
	collectionItems(collectionName) {
		return this._actionResponse(apiAction.list, this.http.get(`/collections/${collectionName}`));
	}

	// Filter items of a collection
	filterCollectionItems(collectionName, filterData) {
		return this._actionResponse(apiAction.list, this.http.post(`/filter/${collectionName}`, filterData));
	}

	// Get single item of a collection
	getCollectionItem(collectionName: string, itemId: string) {
		return this._actionResponse(
			apiAction.editorItem,
			this.http.get(`/collections/${collectionName}/${itemId}`),
		);
	}

	// Update single item of a collection
	updateCollectionItem(collectionName: string, itemId: string, data: object) {
		return this._actionResponse(
			apiAction.editorItem,
			this.http.post(`/collections/${collectionName}/${itemId}`, data),
		);
	}

	// Update items of a collection
	exportCollectionItems(collectionName: string, data: string[]) {
		return this.http.post(`/collections/${collectionName}/export`, data);
	}

	// Delete single item of a collection
	addNewCollectionItem(collectionName: string, data: object) {
		return this._actionResponse(
			apiAction.editorItem,
			this.http.post(`/collections/${collectionName}/new`, data),
		);
	}

	// Delete single item of a collection
	deleteCollectionItem(collectionName: string, items: string[]) {
		return this._actionResponse(apiAction.editorItem, this.http.post(`/delete/${collectionName}`, { items }));
	}

	searchCollectionItem(_data) {
		return this._actionData(this._actionResponse(apiAction.search, this.http.post(`/search`, _data)));
	}

	addNewMisc(collectionName: string, newItem) {
		let _data;
		switch (collectionName) {
			case _db.files:
				{
					_data = new FormData();
					_data.append('doc', newItem);
				}
				break;
			case _db.news:
				{
					_data = new FormData();
					_data.append('title', newItem.title);
					_data.append('date', newItem.date);
					_data.append('file', newItem.file);
					_data.append('order', newItem.order);
				}
				break;
			case _db.articles:
				{
					_data = new FormData();

					if (typeof newItem.categories === 'string') {
						_data.append('categories', newItem.categories);
					} else {
						newItem.categories.forEach((category) => {
							_data.append('categories', category);
						});
					}
					_data.append('title', newItem.title);
					_data.append('file', newItem.file);
					_data.append('image', newItem.image);
					_data.append('readingTime', newItem.readingTime);
					_data.append('description', newItem.description);
					_data.append('language', newItem.language);
					_data.append('order', newItem.order);
				}
				break;
			default: {
				_data = newItem;
				break;
			}
		}

		return this._actionData(
			this._actionResponse(apiAction.fileAction, this.http.post(`/collections/${collectionName}/new`, _data)),
		);
	}

	updateMiscFile(collectionName: string, _item) {
		const _data = new FormData();
		_data.append('doc', _item.files[0]);
		_data.append('fileName', _item.name);
		return this._actionData(
			this._actionResponse(
				apiAction.fileAction,
				this.http.post(`/collections/${collectionName}/updateFile`, _data),
			),
		); // UpdateFile в конце урла надо, что бы роут правильно отрабатывал, это временный костыль, пока используется несколько баз данных
	}

	updateMisc(collectionName: string, itemId: string, _item: object) {
		return this._actionData(
			this._actionResponse(
				apiAction.fileAction,
				this.http.post(`/collections/${collectionName}/${itemId}`, _item),
			),
		);
	}

	deleteMisc(collectionName: string, itemId: string) {
		return this._actionData(
			this._actionResponse(
				apiAction.fileAction,
				this.http.post(`/delete/${collectionName}/`, { items: [itemId] }),
			),
		);
	}

	reorderMisc(collectionName: string, collectionItems: { _id: string; order: string }[]) {
		return this._actionResponse(
			apiAction.editorItem,
			this.http.post(`/collections/${collectionName}/reorder`, collectionItems),
		);
	}

	getLogs(limit = 250, offset = 0) {
		return this._actionData(
			this._actionResponse(apiAction.logs, this.http.post(`/filter/logs/full`, { limit, offset })),
		);
	}

	approveLog(logId: string) {
		return this._actionData(
			this._actionResponse(apiAction.approve, this.http.post(`/approve/${logId}`, null)),
		);
	}

	checkLokaliseToken(token: string) {
		return this.http.post('/lokalise/checkToken', { token });
	}

	getLokaliseData(collections: _localise_db[]) {
		return this._actionResponse(apiAction.lokalise, this.http.post('/lokalise/get', { collections }));
	}

	syncFieldsToLokalise(fieldsId: string[], isBulk = null) {
		return this._actionResponse(
			apiAction.lokalise,
			this.http.post(
				'/lokalise/syncKeysToLokalise',
				{ fieldsId, isBulk },
				{ headers: { timeout: '300000' } },
			),
		);
	}

	syncWithLokalise(fieldsId: string[], isBulk = null) {
		return this._actionResponse(
			apiAction.lokalise,
			this.http.post('/lokalise/syncWithLokalise', { fieldsId, isBulk }, { headers: { timeout: '300000' } }),
		);
	}

	resetLokalise() {
		return this.http.post('/lokalise/reset', null);
	}

	updateLokaliseTags() {
		return this.http.post('/lokalise/updateTags', null);
	}

	updateLokaliseData(collections: _localise_db[]) {
		return EMPTY;
	}

	ngOnDestroy() {}

	private _message = (data, severity = 'error') => {
		if (data && data.summary && data.detail) {
			this.toast.set(data.summary, data.detail, data.severity || severity);
		}
	};

	private _actionObMessageCode(_code, _message) {
		this._message(this.toast.generateMessageFromMsgCode(_code, _message));
		if (_code >= 200) {
			this.tools.setEditorState('wait');
			this.tools.contentLocked.next(false);
		}
		this.tools.contentLoading.next(false);
		this.tools.contextLoading.next(false);
	}

	// INFO: handle error that could occur during request calcs
	private _actionResponse = (action: string, subject: any) =>
		subject.pipe(
			tap((_) => this.tools.contentLocked.next(false)),
			untilDestroyed(this),
			catchError((err) => {
				let _throwError = true;
				if (err.summary && err.detail) this._message(err);
				switch (err.name || err) {
					case 'TimeoutError':
						{
							this._message(
								{
									summary: 'Timeout Error',
									detail: `Request time exceed the limit of ${this.requestTimeout / 1000}s. Try again.`,
								},
								'warn',
							);
							this.tools.contentLoading.next(false);
							this.tools.contentLocked.next(true);
							_throwError = false;
						}
						break;
					case 'Not Found':
						{
							this._message(
								{ summary: 'Not Found', detail: 'Server not fount requested page. Administrator notified.' },
								'warn',
							);
							this.tools.contentLocked.next(true);
							_throwError = false;
						}
						break;
					case 'Bad Request':
						{
							this._message(
								{ summary: 'Bad Request', detail: 'Request is incorrect. Administrator notified.' },
								'warn',
							);
						}
						break;
					case 'CastError':
						{
							this._message({ summary: err.name, detail: err.message }, 'warn');
						}
						break;
				}

				return _throwError ? throwError(err) : of({});
			}),
			materialize(),
			mergeMap((val: Notification<any>) => {
				let _mapTo;
				switch (val.kind) {
					case 'E':
						{
							if (val.error && val.error.messageCode) {
								this._actionObMessageCode(val.error.messageCode, val.error.message);
							}
							_mapTo = { action, response: null };
						}
						break;
					case 'N':
						{
							if (val.hasValue && val.value.messageCode) {
								this._actionObMessageCode(val.value.messageCode, val.value.message);
							}
							_mapTo = { action, response: val.hasValue ? val.value : null };
						}
						break;
				}
				if (_mapTo) {
					this.tools.contextLoading.next(false);
					this.tools.apiState.next(_mapTo);
					return of(_mapTo);
				}
				return EMPTY;
			}),
		);

	private _actionData = (subject: any) =>
		subject.pipe(map((_: IDataResponse<any>) => _.response && _.response.data));
}
