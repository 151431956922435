import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

@Injectable({ providedIn: 'root' })
export class OfficeGuard implements CanActivate {
	constructor(private http: HttpClient, private router: Router) {}

	canActivate(route: ActivatedRouteSnapshot) {
		const _api = window.location.origin.includes('q9capital.com') ? environment.apiQ9 : environment.apiHYCM;

		return this.http.get(`${_api}/frontend/geoIp`).pipe(
			map((val: any) => {
				if (val && val?.office) {
					return true;
				}
				return this.router.parseUrl('/403');
			}),
		);
	}
}
