import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';
import { delay, map } from 'rxjs/operators';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
	constructor() {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		let _url;

		if (request.url.includes('/frontend/geoIp')) {
			return next.handle(request);
		}

		if (request.url.includes('/public/s3/')) {
			_url = environment.api.replace('/v1/private', '') + request.url;

			return next.handle(
				request.clone({
					url: _url,
				}),
			);
		}

		if (request.url.includes('detectlanguage')) {
			_url = request.url;
		}
		//  else if (request.url.includes('lokalise')) {
		// 	_url = request.url;
		// }
		else {
			_url = environment.api + request.url;
		}

		const apiReq = request.clone({
			url: _url,
		});

		return next.handle(apiReq).pipe(
			map((evt: any) => {
				// Add prefix 'lokalise' for all lokalise keys (ex. token -> lokaliseToken)
				if (evt instanceof HttpResponse) {
					if (evt.body) {
						if (request.url.includes('lokalise')) {
							evt = evt.clone({
								body: {
									...evt.body,
									data: Object.keys(evt.body.data).reduce(
										(acc, key) => {
											const isInitialValue = key.indexOf('__') === 0;
											return Object.assign({}, acc, {
												[isInitialValue ? 'initialValues' : 'formValues']: {
													...acc[isInitialValue ? 'initialValues' : 'formValues'],
													[isInitialValue
														? key
														: `lokalise${key.substr(0, 1).toUpperCase()}${key.substr(1)}`]: evt.body.data[
														key
													],
												},
											});
										},
										{
											formValues: {},
											initialValues: {},
										},
									),
								},
							});
						}
						return evt;
					}
				}
			}),
		);
	}
}
