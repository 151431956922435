import { Component, OnDestroy } from '@angular/core';
import { FormService } from '@app/shared/services/forms.service';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subscription } from 'rxjs';

@Component({
	selector: 'add-news',
	templateUrl: './add-news.component.html',
	providers: [FormService],
})
export class AddNewsComponent implements OnDestroy {
	private unsubscribe$ = new Subscription();

	constructor(public fs: FormService, private ref: DynamicDialogRef, private config: DynamicDialogConfig) {
		fs.init('article');
		fs.getField('order').patchValue(this.config.data.news.length + 1);
	}

	onSave() {
		if (this.fs.formValid) {
			this.ref.close(this.fs.form.value);
		}
	}

	onCancel() {
		this.ref.close(null);
	}

	onSelectFile(e) {
		this.fs.form.get('file').patchValue(e.currentFiles[0]);
	}

	onRemoveFile(e) {
		this.fs.form.get('file').patchValue(null);
	}

	onSelectImage(e) {
		this.fs.form.get('image').patchValue(e.currentFiles[0]);
	}

	onRemoveImage(e) {
		this.fs.form.get('image').patchValue(null);
	}

	ngOnDestroy() {
		this.unsubscribe$.unsubscribe();
	}
}
