import { Component, ViewEncapsulation } from '@angular/core';

@Component({
	template: `
		<div style="text-align:center"><h1>403 Forbidden</h1></div>
		<hr />
		<div style="text-align:center">nginx</div>
	`,
	encapsulation: ViewEncapsulation.None,
})
export class ForbiddenComponent {}
