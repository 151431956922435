import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FileNameTrimPipe } from '@app/components/editor/misc-form/articles/pipes/filename-trim.pipe';
import { ForbiddenComponent } from './components/403.component';
import { ServerLostComponent } from './components/502.component';
import { CodeHighlighterDirective } from './directives/code-highlighter.directive';
import { FormFieldVisibleDirective } from './directives/editor-field-visible.directive';
import { InputPrefixDirective } from './directives/input-prefix.directive';
import { StripTagsDirective } from './directives/strip-html.directive';
import { StripSpacesDirective } from './directives/strip-spacing.directive';
import {
	AttachViewDirective,
	DisableViewDirective,
	DiscardViewDirective,
} from './directives/user-permission.directive';

@NgModule({
	declarations: [
		DiscardViewDirective,
		StripSpacesDirective,
		StripTagsDirective,
		InputPrefixDirective,
		FormFieldVisibleDirective,
		ServerLostComponent,
		ForbiddenComponent,
		CodeHighlighterDirective,
		AttachViewDirective,
		DisableViewDirective,
		FileNameTrimPipe,
	],
	exports: [FormFieldVisibleDirective, CodeHighlighterDirective, FileNameTrimPipe],
	imports: [CommonModule],
})
export class SharedModule {}
