import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LoginComponent } from './components/auth/login.component';
import { EditFormComponent } from './components/editor/edit-form/edit-form.component';
import { ArticlesFormComponent } from './components/editor/misc-form/articles/articles.component';
import { FilesFormComponent } from './components/editor/misc-form/files/files.component';
import { NewsFormComponent } from './components/editor/misc-form/news/news.component';
import { VideosFormComponent } from './components/editor/misc-form/videos/videos.component';
import { OverallInfoComponent } from './components/editor/overall-info/overall-info.component';
import { MainComponent } from './components/main.component';
import { ForbiddenComponent } from './shared/components/403.component';
import { ServerLostComponent } from './shared/components/502.component';
import { NavigationGuard } from './shared/guards/navigation.guard';
import { OfficeGuard } from './shared/guards/office.guard';
import { PermissionGuard } from './shared/guards/permission.guard';
import { TokenGuard } from './shared/guards/token.guard';
import { InitialValuesGuard } from './shared/resolvers/context.resolver';
import { InfoContextGuard } from './shared/resolvers/info.resolver';
import { ListContextGuard } from './shared/resolvers/list.resolver';
import { LokaliseValuesGuard } from './shared/resolvers/lokalise-context.resolver';
import { MiscContextGuard } from './shared/resolvers/misc.resolver';

const routes: Routes = [
	{
		path: '',
		redirectTo: 'login',
		pathMatch: 'full',
	},
	{
		path: 'login',
		canActivate: [TokenGuard, OfficeGuard],
		component: LoginComponent,
	},
	{
		path: 'editor',
		component: MainComponent,
		canActivate: [TokenGuard],
		children: [
			{
				path: '',
				pathMatch: 'full',
				component: OverallInfoComponent,
				canActivate: [NavigationGuard],
				resolve: {
					context: InitialValuesGuard,
					list: InfoContextGuard,
				},
			},
			{
				path: 'fields',
				component: EditFormComponent,
				canActivate: [PermissionGuard],
				resolve: {
					context: InitialValuesGuard,
					lokalise: LokaliseValuesGuard,
					list: ListContextGuard,
				},
			},
			{
				path: 'pages',
				component: EditFormComponent,
				canActivate: [PermissionGuard],
				resolve: {
					context: InitialValuesGuard,
					lokalise: LokaliseValuesGuard,
					list: ListContextGuard,
				},
			},
			{
				path: 'categories',
				component: EditFormComponent,
				canActivate: [PermissionGuard],
				resolve: {
					context: InitialValuesGuard,
					lokalise: LokaliseValuesGuard,
					list: ListContextGuard,
				},
			},
			{
				path: 'files',
				component: FilesFormComponent,
				canActivate: [PermissionGuard],
				resolve: {
					context: InitialValuesGuard,
					list: MiscContextGuard,
				},
			},
			{
				path: 'videos',
				component: VideosFormComponent,
				canActivate: [PermissionGuard],
				resolve: {
					context: InitialValuesGuard,
					list: MiscContextGuard,
				},
			},
			{
				path: 'news',
				component: NewsFormComponent,
				canActivate: [PermissionGuard],
				resolve: {
					context: InitialValuesGuard,
					list: MiscContextGuard,
				},
			},
			{
				path: 'articles',
				component: ArticlesFormComponent,
				canActivate: [PermissionGuard],
				resolve: {
					context: InitialValuesGuard,
					list: MiscContextGuard,
				},
			},
			{
				path: 'users',
				component: EditFormComponent,
				canActivate: [PermissionGuard],
				resolve: {
					context: InitialValuesGuard,
					lokalise: LokaliseValuesGuard,
					list: ListContextGuard,
				},
			},
			// {
			//   path: 'logs',
			//   component: EditFormComponent,
			//   canActivate: [PermissionGuard],
			//   resolve: {
			//     context: InitialValuesGuard,
			//     lokalise: LokaliseValuesGuard,
			//     list: ListContextGuard
			//   }
			// },
			{
				path: 'languages',
				component: EditFormComponent,
				canActivate: [PermissionGuard],
				resolve: {
					context: InitialValuesGuard,
					lokalise: LokaliseValuesGuard,
					list: ListContextGuard,
				},
			},
			{
				path: 'jurisdictions',
				component: EditFormComponent,
				canActivate: [PermissionGuard],
				resolve: {
					context: InitialValuesGuard,
					lokalise: LokaliseValuesGuard,
					list: ListContextGuard,
				},
			},
			{
				path: 'projects',
				component: EditFormComponent,
				canActivate: [PermissionGuard],
				resolve: {
					context: InitialValuesGuard,
					lokalise: LokaliseValuesGuard,
					list: ListContextGuard,
				},
			},
			{
				path: 'roles',
				component: EditFormComponent,
				canActivate: [PermissionGuard],
				resolve: {
					context: InitialValuesGuard,
					lokalise: LokaliseValuesGuard,
					list: ListContextGuard,
				},
			},
			{
				path: 'builders',
				component: EditFormComponent,
				canActivate: [PermissionGuard],
				resolve: {
					context: InitialValuesGuard,
					lokalise: LokaliseValuesGuard,
					list: ListContextGuard,
				},
			},
			{
				path: 'prefixes',
				component: EditFormComponent,
				canActivate: [PermissionGuard],
				resolve: {
					context: InitialValuesGuard,
					lokalise: LokaliseValuesGuard,
					list: ListContextGuard,
				},
			},
		],
	},
	{ path: '502', component: ServerLostComponent },
	{ path: '403', component: ForbiddenComponent },
	{ path: '**', redirectTo: 'editor' },
];

@NgModule({
	imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
	exports: [RouterModule],
	providers: [
		TokenGuard,
		InitialValuesGuard,
		LokaliseValuesGuard,
		ListContextGuard,
		MiscContextGuard,
		InfoContextGuard,
	],
})
export class AppRoutingModule {}
