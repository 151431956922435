const packageJson = require('../../../../package.json');

export const environment = {
	appName: packageJson.name,
	project: 'admin',
	envName: 'DEV',
	production: false,
	versions: {
		app: packageJson.version,
		angular: packageJson.dependencies['@angular/core'],
		ngrx: packageJson.dependencies['@ngrx/store'],
		rxjs: packageJson.dependencies.rxjs,
		angularCli: packageJson.devDependencies['@angular/cli'],
		typescript: packageJson.devDependencies.typescript,
	},
	api: window.location.origin + '/api/v1/private',
	// api: 'http://localhost:3000',
	// api: 'https://back.test.q9capital.com/api/v1/private',
	// api: 'http://back.hycmlab.com/api/v1/private',
	// api: 'http://localhost:5000/v1/private',
	apiHYCM: 'https://api.hycm.com',
	apiQ9: window.location.origin + '/api/q9proxy',
	// apiQ9: 'https://back.test.q9capital.com/api/q9proxy',
};

// console.log('development environment', environment);
